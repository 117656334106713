import { render, staticRenderFns } from "./aside.vue?vue&type=template&id=3df930f7&scoped=true&"
import script from "./aside.vue?vue&type=script&lang=js&"
export * from "./aside.vue?vue&type=script&lang=js&"
import style0 from "./aside.vue?vue&type=style&index=0&id=3df930f7&prod&lang=scss&scoped=true&rel=stylesheet%2Fscss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3df930f7",
  null
  
)

export default component.exports